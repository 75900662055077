import qs from 'qs';
import Axios from 'axios';

import { toast } from '@cogoport/front/components';
import { formatDateToString } from '@cogoport/front/date';
import { isEmpty, startCase } from '@cogoport/front/utils';

import { getCookie } from '../../cogo-cookies';

const COGO_MAPS_SERVICES = ['location', 'sailing_schedule', 'operator'];

const CX_AUTOMATION_SERVICES = [
	'shipment',
	'invoice',
	'registry-management-service',
];

const FAST_API_SERVICES = [
	'fcl_freight_rate',
	'air_freight_rate',
	'fcl_customs_rate',
	'fcl_cfs_rate',
	'haulage_freight_rate',
	'air_customs_rate',
	'ftl_freight_rate',
	...CX_AUTOMATION_SERVICES,
];

const showError = async (message, type = 'error') => {
	if (typeof window !== 'undefined') {
		if (type === 'warn') {
			toast.warn(message, { hideAfter: 5 });
		} else {
			toast.error(message, { hideAfter: 5 });
		}
	}
};

const getPartnerId = (storeKey, ctx) => {
	if (typeof window !== 'undefined') {
		const getStoreState = window?.[storeKey]?.getState;
		if (typeof getStoreState === 'function') {
			return getStoreState()?.general?.query?.partner_id;
		}
		return null;
	}

	return ctx?.query?.partner_id || null;
};

const decodedCustomSerializer = (params) => {
	const paramsStringify = qs.stringify(params, {
		encode: false,
		arrayFormat: 'repeat',
		serializeDate: (date) => formatDateToString(date),
	});
	return paramsStringify;
};

const customAxios = Axios.create();

const customSerializer = (params) => {
	const paramsStringify = qs.stringify(params, {
		arrayFormat: 'brackets',
		serializeDate: (date) => formatDateToString(date),
	});

	return paramsStringify;
};

const customPeeweeSerializer = (params) => {
	const dataTypes = ['Object', 'Array'].map((d) => `[object ${d}]`);

	const newParams = Object.keys(params).reduce((acc, key) => {
		acc[key] = dataTypes.includes(Object.prototype.toString.call(params[key]))
			? JSON.stringify(params[key])
			: params[key];

		return acc;
	}, {});

	const paramsStringify = qs.stringify(newParams, {
		arrayFormat: 'brackets',
		serializeDate: (date) => formatDateToString(date),
	});

	return paramsStringify;
};

const customGoSerializer = (params) => {
	const dataTypes = ['Object', 'Array'].map((d) => `[object ${d}]`);

	const newParams = Object.keys(params).reduce((acc, key) => {
		acc[key] = dataTypes.includes(Object.prototype.toString.call(params[key]))
			? JSON.stringify(params[key])
			: params[key];

		return acc;
	}, {});

	const paramsStringify = qs.stringify(newParams, {
		arrayFormat: 'repeat',
		serializeDate: (date) => formatDateToString(date),
	});

	return paramsStringify;
};

customAxios.interceptors.request.use((oldAxiosConfig) => {
	const { scope = 'public', authKey = '', url } = oldAxiosConfig;
	let baseURL = process.env.NEXT_PUBLIC_REST_BASE_API_URL;
	const serviceName = url.split('/')?.[0] || url.split('/')?.[1];
	const isDevMode = !process.env.NEXT_PUBLIC_REST_BASE_API_URL.includes(
		'https://api.cogoport.com',
	);

	const peeweeSerializerRequired =
		FAST_API_SERVICES.includes(serviceName) ||
		(COGO_MAPS_SERVICES.includes(serviceName) && !isDevMode);

	if (authKey) {
		baseURL = process.env.NEXT_PUBLIC_BUSINESS_FINANCE_BASE_URL;
	}

	if (FAST_API_SERVICES.includes(serviceName) && isDevMode) {
		baseURL = process.env.NEXT_PUBLIC_STAGE_URL;
	}

	if (CX_AUTOMATION_SERVICES.includes(serviceName)) {
		baseURL = process.env.NEXT_PUBLIC_CX_AUTOMATION_API_URL;
	}

	const newAxiosConfig = {
		...oldAxiosConfig,
		timeout: 300000,
		paramsSerializer: {
			serialize: peeweeSerializerRequired
				? customPeeweeSerializer
				: customSerializer,
		},
		baseURL,
		headers: {
			authorizationscope: 'partner',
			authorizationparameters: 'allowed',
			...(oldAxiosConfig.headers || {}),
			authorizationscopeid: getPartnerId(
				'__COGO_CP_LSP_STORE__',
				oldAxiosConfig.ctx,
			),
		},
	};

	const token = getCookie(
		process.env.NEXT_PUBLIC_AUTH_TOKEN_NAME,
		oldAxiosConfig.ctx,
	);

	if (token) {
		if (isEmpty(newAxiosConfig.headers)) {
			newAxiosConfig.headers = {};
		}
		newAxiosConfig.headers.authorization = `Bearer: ${token}`;
	}

	if (scope === 'business_finance') {
		newAxiosConfig.paramsSerializer = { serialize: decodedCustomSerializer };
		newAxiosConfig.baseURL = process.env.NEXT_PUBLIC_BUSINESS_FINANCE_BASE_URL;
		newAxiosConfig.headers = {
			authorizationscope: 'partner',
			authorizationparameters: 'allowed',
		};
		if (token) {
			newAxiosConfig.headers.authorization = `Bearer: ${token}`;
		}
	}

	if (scope === 'cogocare') {
		newAxiosConfig.baseURL = process.env.NEXT_PUBLIC_TICKET_REST_BASE_API_URL;

		if (!isDevMode) {
			newAxiosConfig.baseURL = `${baseURL}/tickets`;
		}
	}

	if (scope === 'auth') {
		newAxiosConfig.paramsSerializer = { serialize: customGoSerializer };
	}

	return newAxiosConfig;
});

customAxios.interceptors.response.use(
	(response) => ({
		hasError: false,
		data: response?.data,
		status: response?.status,
	}),
	(error) => {
		if (error.response) {
			const { status } = error?.response || {};
			if (status === 400 || status === 500) {
				const res = error?.response?.data;
				const keys = Object.keys(res);

				const errorObj = {};
				keys.forEach((key) => {
					errorObj[key] = `${startCase(key)} ${
						Array.isArray(res[key]) ? (res[key] || []).join(', ') : res[key]
					}`;
				});

				// eslint-disable-next-line prefer-promise-reject-errors
				return Promise.reject({
					status,
					data: errorObj,
					error: error?.response?.data,
				});
			}

			if (error?.response?.status === 401) {
				toast.error('Authentication failed!', 'error');
			} else if (status === 403) {
				//
			} else {
				showError(
					'At this moment, we are unable to service this request',
					'error',
				);
			}

			return Promise.reject(error);
		}

		return Promise.reject(error);
	},
);

export default customAxios;
